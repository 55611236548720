export default {
  "": {
    "id": 1872,
    "temp_key": "",
    "site_id": 29762,
    "name": "",
    "items": [
      {
        "id": "umit18hjrttv",
        "url": null,
        "use": "page",
        "name": "Home",
        "page": "/",
        "blank": null,
        "section": null,
        "document": null,
        "elements": []
      },
      {
        "id": "77h8d2rrr0wh",
        "url": null,
        "use": "page",
        "name": "Ceník",
        "page": "/cenik",
        "blank": null,
        "section": null,
        "document": null,
        "elements": []
      },
      {
        "id": "ngelusbi3o4r",
        "url": null,
        "use": "page",
        "name": "Kontakt",
        "page": "/",
        "blank": null,
        "section": "paticka",
        "document": null,
        "elements": []
      }
    ]
  }
}